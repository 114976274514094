import React, { useEffect } from 'react'
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './scss/style.scss'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-datepicker/dist/react-datepicker.css'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Logout = React.lazy(() => import('./views/pages/logout/Logout'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'))

const App = () => {
  const { access_token, user_data } = useSelector((state) => state)

  useEffect(() => {
    console.log('user_data', user_data)
  }, [user_data])

  return (
    <HashRouter>
      <React.Suspense fallback={loading}>
        <Switch>
          <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
          <Route
            exact
            path="/logout"
            name="Logout Page"
            render={(props) => <Logout {...props} />}
          />
          <Route
            exact
            path="/register"
            name="Register Page"
            render={(props) => <Register {...props} />}
          />
          <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
          <Route exact path="/500" name="Page 500" render={(props) => <Page500 {...props} />} />
          <Route
            path="/"
            name="Home"
            render={(props) => {
              if (access_token && user_data) {
                return <DefaultLayout {...props} />
              }

              return (
                <Redirect
                  to={{
                    pathname: '/login',
                  }}
                />
              )
            }}
          />
        </Switch>
      </React.Suspense>
    </HashRouter>
  )
}

export default App
