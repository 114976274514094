import { createStore } from 'redux'
import SecureLS from 'secure-ls'

// const encryption_secret = 'L0v3Y0uRN'
const secureLs = new SecureLS()

const initial_state = {
  sidebarShow: true,
  user_data: secureLs.get('promag-user_data'),
  access_token: secureLs.get('promag-access_token'),
  global_branch: secureLs.get('promag-global_branch') ? secureLs.get('promag-global_branch') : {},
}

const variables = {
  main_dealer_id: 4,
  api_base_url: 'https://dev-salesforce.southeastasia.cloudapp.azure.com/promag/api/',
  product_img_url: 'https://flowerbasket.blob.core.windows.net/images/dorayaki/product/',
  recaptcha_site_key: '6LdVxk0hAAAAAAC2RIXZzf3G2l6tzlADL3_mPmZL',
}

const changeState = (state = initial_state, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return { ...state, variables }
  }
}

const store = createStore(changeState)
export default store
